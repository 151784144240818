import React, { Component } from "react";
import { connect } from "react-redux";
import connector from "./Registration.connect";
import Flex from "components/grid/Flex";
import { withRouter } from "utils/Route.utils";
import { withTranslation } from "react-i18next";
import { Autocomplete } from "@material-ui/lab";
import { Button, IconButton, InputAdornment, MenuItem, Typography, Checkbox } from "@material-ui/core";
import DefaultTextField from "components/hmo-textfield/DefaultTextField";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import PasswordChecklist from "react-password-checklist";
import { ReactComponent as KrebsZweitmeinungLogo } from "assets/img/krebszweitmeinung-logo.svg";
import CHIP_icon from "assets/img/CHIP_clinic_icon.png";
import LanguageSelector from "components/language-selector/LanguageSelector";
import { Link } from "react-router-dom";
import { LANDING } from "routes/routes";
import EpiAlert from "components/alert/EpiAlert";
import PdfViewer from "components/pdf-viewer/PdfViewer";
import _, { debounce } from "lodash";
import { InvalidTokenView } from "./InvalidTokenView";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import ConfirmationDialog from "../../components/confirmation-dialog/ConfirmationDialog";

/**
 * Sadly, even after copying the homework of chrome, Edge is still useless. How were they able to achieve ruining a browser
 * they only had copy and rebrand is a mystery. Maybe they used the teams responsible for Skype. Who knows...
 * Nonetheless, we need to turn off autofill to save the uninitiated from whatever the fuck edge does instead of it.
 */
const EdgeSafeDefaultTextField = (props) => {
    const { inputProps, ...other } = props;
    const isEdge = /Edg/.test(navigator.userAgent) || /Edg/.test(window.navigator.userAgent);
    return (
        <DefaultTextField
            {...other}
            inputProps={{
                ...(isEdge && {
                    "aria-autocomplete": "none",
                    autocomplete: "off",
                }),
                ...inputProps,
            }}
        />
    );
};

const getRegistrationResponseErrorMessageForAlert = (errorCode) => {
    switch (errorCode) {
        case "406":
            return "global.confirm-identity-call-failed";
        case "401":
            return "login.incorrect-verification";
        default:
            return "global.error";
    }
};

function processRegistrationError(error, t) {
    const message = error?.error?.response?.data;
    const errorCode = `${error?.error?.response?.status}`;
    if (message && message.includes("Invalid email")) {
        return t("global.invalid-email");
    }
    if (message && message.includes("Captcha verification failed")) {
        return "Captcha verification failed";
    }
    return t(getRegistrationResponseErrorMessageForAlert(errorCode));
}

export class Registration extends Component {
    constructor(props) {
        super(props);
        this.captchaRef = React.createRef(null);
    }

    state = {
        inviteToken: "",
        givenName: "",
        familyName: "",
        emailAddress: "",
        phoneNumber: "",
        salutation: "",
        title: "",
        password: "",
        passwordRepeat: "",
        isValidPassword: true,
        isPasswordVisible: false,
        alert: "",
        errorCode: "",
        registrationSuccessful: false,
        isPrivacyPolicyRead: false,
        isDeclarationOfParticipationRead: false,
        isDeclarationOfParticipationOpen: false,
        isPrivacyPolicyOpen: false,
        privacyPolicyFile: "",
        declarationOfParticipationFile: "",
        careProviders: [],
        selectedCareProvider: "",
        emailConfirmationStage: false,
        emailConfirmationToken: "",
        emailConfirmationId: "",
        inviteTokenError: false,
        captchaToken: "",
        emailNotAcceptable: false,
    };

    handleStateChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    };

    handleEmailChange = (event) => {
        this.setState({
            emailAddress: event.target.value?.toLowerCase(),
        });
    };

    toggleEmailNotAcceptable = () => {
        this.setState({
            emailNotAcceptable: !this.state.emailNotAcceptable,
        });
    };

    componentDidMount() {
        this.props.silentLogout();
        const inviteToken = this.props.routeParams.inviteToken;
        const isTokenAUuid = inviteToken && inviteToken?.includes("-");
        const isTokenACareProviderId = inviteToken && !inviteToken?.includes("-") && _.isFinite(Number(inviteToken));
        if (isTokenAUuid) {
            this.props.getEmailForToken(this.props.routeParams.inviteToken).then((response) => {
                this.setState({
                    inviteToken,
                    emailAddress: response?.payload?.data?.invitedEmail,
                    inviteTokenError: _.isEmpty(response?.payload?.data),
                });
            });
        }

        this.props.listCareProvidersForRegistration().then((response) => {
            this.setState({
                careProviders: response?.payload?.data,
                selectedCareProvider: isTokenACareProviderId
                    ? response?.payload?.data?.find((careProvider) => careProvider.id === Number(inviteToken))
                    : "",
            });
        });
    }

    togglePasswordVisibility = () => {
        this.setState((state) => ({ isPasswordVisible: !state.isPasswordVisible }));
    };

    validatePassword = (isValid) => {
        this.setState({ isValidPassword: isValid });
    };

    handleSubmit = () => {
        if (this.state.emailConfirmationStage || !!this.state.inviteToken) {
            this.setState(
                {
                    registrationSuccessful: false,
                    errorCode: "",
                    alert: "",
                },
                () => {
                    this.props
                        .register({
                            emailAddress: this.state.emailAddress?.toLowerCase(),
                            password: this.state.password,
                            givenName: this.state.givenName,
                            familyName: this.state.familyName,
                            salutation: this.state.salutation,
                            title: this.state.title,
                            inviteToken: this.state.inviteToken,
                            phoneNumber: this.state.phoneNumber,
                            selectedCareProviderId: this.state.selectedCareProvider?.id,
                            emailConfirmationToken: this.state.emailConfirmationToken,
                            emailConfirmationId: this.state.emailConfirmationId,
                            captchaToken: this.state.captchaToken,
                        })
                        .then(() => {
                            this.setState({
                                alert: "success",
                                registrationSuccessful: true,
                            });
                        })
                        .catch((err) => {
                            const errorCode = `${err?.error?.response?.status}`;
                            this.setState({
                                alert: processRegistrationError(err, this.props.t),
                                errorCode,
                            });
                        });
                },
            );
        } else {
            this.props
                .requestTokenForEmail(this.state.emailAddress.toLowerCase(),
                        this.state.selectedCareProvider?.id || 0)
                .then((response) => {
                    this.setState({
                        emailConfirmationId: response?.payload?.data,
                        emailConfirmationStage: true,
                    });
                })
                .catch((error) => {
                    if (`${error?.error?.response?.status}`.includes("406")) {
                        this.setState({
                            emailNotAcceptable: true,
                        });
                    } else {
                        this.setState({
                            alert: "error",
                        });
                    }
                });
        }
    };

    closeSnackbar = () => {
        this.setState({ alert: "" });
    };

    goToLogin = () => {
        this.props.navigate("/");
    };

    toggleIsPrivacyPolicyRead = () => {
        this.setState({ isPrivacyPolicyRead: !this.state.isPrivacyPolicyRead });
    };

    toggleIsDeclarationOfParticipationRead = () => {
        this.setState({ isDeclarationOfParticipationRead: !this.state.isDeclarationOfParticipationRead });
    };

    toggleIsPrivacyPolicyOpen = () => {
        this.setState({ isPrivacyPolicyOpen: !this.state.isPrivacyPolicyOpen });
    };

    toggleIsDeclarationOfParticipationOpen = () => {
        this.setState({ isDeclarationOfParticipationOpen: !this.state.isDeclarationOfParticipationOpen });
    };

    downloadAndShowPrivacyPolicy = () => {
        if (this.state.privacyPolicyFile) {
            this.setState({ isPrivacyPolicyOpen: true });
        } else {
            this.props.downloadPrivacyPolicy(this.state.selectedCareProvider?.id).then((privacyPolicyFile) => {
                this.setState({
                    privacyPolicyFile,
                    isPrivacyPolicyOpen: true,
                });
            });
        }
    };

    downloadAndShowDeclarationOfParticipation = () => {
        if (this.state.declarationOfParticipationFile) {
            this.setState({ isDeclarationOfParticipationOpen: true });
        } else {
            this.props
                .downloadDeclarationOfParticipation(this.state.selectedCareProvider?.id)
                .then((declarationOfParticipationFile) => {
                    this.setState({
                        declarationOfParticipationFile,
                        isDeclarationOfParticipationOpen: true,
                    });
                });
        }
    };

    selectCareProvider = (event, selectedCareProvider) => {
        this.setState({
            selectedCareProvider,
            privacyPolicyFile: "",
            declarationOfParticipationFile: "",
        });
    };

    handleResendEmail = debounce(() => {
        this.props.requestTokenForEmail(this.state.emailAddress.toLowerCase(),
                this.state.selectedCareProvider?.id).then((response) => {
            this.setState({
                emailConfirmationId: response?.payload?.data,
                emailConfirmationStage: true,
                alert: this.props.t("global.re-verification"),
            });
        });
    }, 300);

    render() {
        const { t: translate, routeParams, i18n } = this.props;
        const {
            password,
            passwordRepeat,
            isValidPassword,
            isPasswordVisible,
            givenName,
            familyName,
            emailAddress,
            salutation,
            title,
            phoneNumber,
            alert,
            registrationSuccessful,
            errorCode,
            inviteToken,
            isPrivacyPolicyRead,
            isDeclarationOfParticipationRead,
            isDeclarationOfParticipationOpen,
            isPrivacyPolicyOpen,
            privacyPolicyFile,
            declarationOfParticipationFile,
            careProviders,
            selectedCareProvider,
            emailConfirmationStage,
            emailConfirmationToken,
            inviteTokenError,
            captchaToken,
            emailNotAcceptable,
        } = this.state;
        const {
            togglePasswordVisibility,
            handleStateChange,
            validatePassword,
            handleSubmit,
            closeSnackbar,
            goToLogin,
            handleEmailChange,
            toggleIsPrivacyPolicyRead,
            toggleIsDeclarationOfParticipationRead,
            downloadAndShowPrivacyPolicy,
            downloadAndShowDeclarationOfParticipation,
            toggleIsPrivacyPolicyOpen,
            toggleIsDeclarationOfParticipationOpen,
            selectCareProvider,
            toggleEmailNotAcceptable,
        } = this;
        const routeInviteToken = routeParams.inviteToken;

        if (inviteTokenError && routeInviteToken && routeInviteToken.includes("-")) {
            return <InvalidTokenView />;
        }

        // highly volatile way to check if real HMO CP is selected.
        const isHmoSelected = selectedCareProvider?.name?.toLowerCase().startsWith("hmo");

        console.log();
        return (
            <Flex item container column center>
                <Flex item container justifyContent={"flex-end"} style={{ width: "100%" }} padding={8}>
                    <LanguageSelector />
                </Flex>
                <Flex
                    item
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                    style={{ width: "100%", gap: "28px", padding: 10 }}
                >
                    {(isHmoSelected || !selectedCareProvider) && <KrebsZweitmeinungLogo style={{ height: 50 }} />}
                    {!isHmoSelected && <img src={CHIP_icon} alt={""} style={{ height: 50 }} />}
                </Flex>
                <Flex item container column style={{ width: "100%", maxWidth: 550, padding: 10 }}>
                    {registrationSuccessful && <Typography>{translate("login.register-success")}</Typography>}
                    {!registrationSuccessful && (
                        <>
                            <Typography style={{ paddingBottom: "4px" }} variant={"h6"}>
                                {isHmoSelected || !selectedCareProvider
                                    ? translate("register.title")
                                    : translate("register.chip.title")}
                            </Typography>
                            <Typography style={{ fontSize: 15 }}>
                                {isHmoSelected || !selectedCareProvider
                                    ? translate("register.subtitle")
                                    : translate("register.chip.subtitle")}
                            </Typography>
                            {inviteToken && (
                                <Typography style={{ margin: "10px 0px" }}>
                                    {translate("register.description")}
                                </Typography>
                            )}
                            <EdgeSafeDefaultTextField
                                value={emailAddress}
                                error={!emailAddress || !!errorCode}
                                disabled={!!inviteToken || emailConfirmationStage}
                                name={"emailAddress"}
                                label={translate("register.email")}
                                helperText={errorCode === "406" ? translate(`global.REUSED_EMAIL`) : ""}
                                onChange={handleEmailChange}
                            />
                            <EdgeSafeDefaultTextField
                                value={phoneNumber}
                                error={!phoneNumber}
                                name={"phoneNumber"}
                                label={translate("case.phone")}
                                onChange={handleStateChange}
                            />

                            <EdgeSafeDefaultTextField
                                label={translate("case.salutation")}
                                data-testid="registration-person-salutation"
                                select
                                SelectProps={{
                                    value: salutation || "",
                                    name: "salutation",
                                    onChange: handleStateChange,
                                }}
                            >
                                {["Frau", "Herr"].map((salutation) => (
                                    <MenuItem key={salutation} value={salutation}>
                                        {translate(`global.${salutation}`)}
                                    </MenuItem>
                                ))}
                            </EdgeSafeDefaultTextField>
                            <EdgeSafeDefaultTextField
                                value={title}
                                name={"title"}
                                label={translate("case.title")}
                                onChange={handleStateChange}
                            />
                            {careProviders && !_.isEmpty(careProviders) && !routeInviteToken?.includes("-") && (
                                <Autocomplete
                                    data-testid="registration-care-provider-select"
                                    options={careProviders || []}
                                    onChange={selectCareProvider}
                                    disabled={routeInviteToken && !routeInviteToken?.includes("-")}
                                    name={"selectedCareProvider"}
                                    value={selectedCareProvider || null}
                                    getOptionSelected={(option, value) => {
                                        return option.id === value.id;
                                    }}
                                    style={{ marginRight: 7 }}
                                    getOptionLabel={(careProvider) => careProvider.name}
                                    renderOption={(careProvider) => careProvider.name}
                                    renderInput={(params) => (
                                        <EdgeSafeDefaultTextField
                                            {...params}
                                            label={translate("global.care-provider")}
                                            placeholder={translate("global.optional")}
                                        />
                                    )}
                                />
                            )}
                            <EdgeSafeDefaultTextField
                                value={givenName || ""}
                                label={translate("case.first-name")}
                                error={!givenName}
                                name={"givenName"}
                                onChange={handleStateChange}
                            />

                            <EdgeSafeDefaultTextField
                                label={translate("case.last-name")}
                                value={familyName || ""}
                                onChange={handleStateChange}
                                error={!familyName}
                                name={"familyName"}
                            />
                            <EdgeSafeDefaultTextField
                                name={"password"}
                                label={translate("login.password")}
                                error={!isValidPassword}
                                type={isPasswordVisible ? "text" : "password"}
                                value={password}
                                onChange={handleStateChange}
                                inputProps={{
                                    autoComplete: "new-password",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabindex="-1"
                                                aria-label="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <EdgeSafeDefaultTextField
                                name={"passwordRepeat"}
                                label={translate("login.reset-password-new-repeat")}
                                error={!isValidPassword}
                                type={isPasswordVisible ? "text" : "password"}
                                value={passwordRepeat}
                                onChange={handleStateChange}
                                style={{ marginBottom: 10 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                tabindex="-1"
                                                aria-label="Toggle password visibility"
                                                onClick={togglePasswordVisibility}
                                            >
                                                {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                minLength={10}
                                style={{ fontSize: 12 }}
                                value={password}
                                valueAgain={passwordRepeat}
                                onChange={validatePassword}
                                messages={{
                                    minLength: translate("global.minLength"),
                                    specialChar: translate("global.specialChar"),
                                    number: translate("global.password-number"),
                                    capital: translate("global.capital"),
                                    match: translate("global.match"),
                                }}
                            />
                            <Flex item container column style={{ marginTop: 10 }}>
                                <Flex item container>
                                    <Checkbox
                                        style={{
                                            padding: 5,
                                            marginRight: 5,
                                            display: "flex",
                                            alignItems: "flex-start",
                                        }}
                                        checked={isPrivacyPolicyRead}
                                        onChange={toggleIsPrivacyPolicyRead}
                                    />
                                    <Flex>
                                        <Typography style={{ fontSize: 14, display: "inline" }}>
                                            {translate(`register.privacy-text-1`)}
                                        </Typography>
                                        <Typography
                                            onClick={downloadAndShowPrivacyPolicy}
                                            style={{ fontSize: 14, display: "inline", color: "blue" }}
                                        >
                                            {translate(`register.privacy-policy`)}
                                        </Typography>
                                        <Typography style={{ fontSize: 14, display: "inline" }}>
                                            {translate(`register.privacy-text-2`)}
                                        </Typography>
                                    </Flex>
                                </Flex>
                                <Flex item container>
                                    <Checkbox
                                        style={{
                                            padding: 5,
                                            marginRight: 5,
                                            display: "flex",
                                            alignItems: "flex-start",
                                        }}
                                        checked={isDeclarationOfParticipationRead}
                                        onChange={toggleIsDeclarationOfParticipationRead}
                                    />
                                    <Flex>
                                        <Typography style={{ fontSize: 14, display: "inline" }}>
                                            {translate(`register.participation-text`)}
                                        </Typography>
                                        <Typography
                                            onClick={downloadAndShowDeclarationOfParticipation}
                                            style={{ fontSize: 14, display: "inline", color: "blue" }}
                                        >
                                            {translate(`register.declaration-of-participation`)}
                                        </Typography>
                                    </Flex>
                                </Flex>
                            </Flex>
                            <div style={{ marginTop: "20px" }}>
                                <HCaptcha
                                    ref={this.captchaRef}
                                    languageOverride={i18n.language}
                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                    onVerify={(token) => {
                                        this.setState({ captchaToken: token });
                                    }}
                                />
                            </div>
                            {process.env.NODE_ENV === "development" && (
                                <div
                                    data-testid="hcaptcha"
                                    style={{ display: "none" }}
                                    onClick={() => this.captchaRef.current.execute()}
                                ></div>
                            )}
                            {emailConfirmationStage && (
                                <>
                                    <div>
                                        <Typography style={{ marginTop: 10 }}>
                                            {translate("login.two-factor-label")}
                                        </Typography>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <EdgeSafeDefaultTextField
                                            style={{ flex: 1 }}
                                            label={translate("login.verifyCode")}
                                            value={emailConfirmationToken}
                                            onChange={handleStateChange}
                                            name={"emailConfirmationToken"}
                                        />
                                        <Button
                                            size="small"
                                            onClick={this.handleResendEmail}
                                            variant="outlined"
                                            style={{ marginTop: "12px", height: "40px" }}
                                        >
                                            Resend email
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    <Flex item container style={{ marginTop: 20 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            component={Link}
                            to={LANDING.path}
                            onClick={() => {}}
                            style={{ marginRight: 10 }}
                        >
                            {translate("global.back")}
                        </Button>
                        {!registrationSuccessful && (
                            <Button
                                fullWidth
                                data-testid="submit-registration"
                                variant="contained"
                                color="primary"
                                disabled={
                                    !emailAddress ||
                                    !isValidPassword ||
                                    !familyName ||
                                    !givenName ||
                                    !phoneNumber ||
                                    !isPrivacyPolicyRead ||
                                    !isDeclarationOfParticipationRead ||
                                    !captchaToken ||
                                    (emailConfirmationStage && emailConfirmationToken?.length < 6)
                                }
                                style={{ marginLeft: 10 }}
                                onClick={handleSubmit}
                            >
                                {!inviteToken && !emailConfirmationStage
                                    ? translate("global.send-verification")
                                    : translate("global.register")}
                            </Button>
                        )}
                        {registrationSuccessful && (
                            <Button
                                data-testid="go-to-login-on-registration-success-btn"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 10 }}
                                onClick={goToLogin}
                            >
                                {translate("login.login-button")}
                            </Button>
                        )}
                    </Flex>
                    <EpiAlert
                        {...{
                            autoHideDuration: Math.max(alert?.length * 70, 6000),
                            isOpen: !!alert,
                            close: closeSnackbar,
                            severity: alert === "success" ? "success" : "error",
                            message: alert === "success" ? translate(`global.success`) : alert,
                        }}
                    />
                </Flex>

                {emailNotAcceptable && (
                    <ConfirmationDialog
                        singleConfirmationButton={true}
                        dialogOpen={emailNotAcceptable}
                        onConfirm={toggleEmailNotAcceptable}
                        confirmationTextKey={"register.email-not-acceptable"}
                    />
                )}

                {privacyPolicyFile && isPrivacyPolicyOpen && (
                    <PdfViewer fileBlobUrl={privacyPolicyFile} onClose={toggleIsPrivacyPolicyOpen} />
                )}
                {declarationOfParticipationFile && isDeclarationOfParticipationOpen && (
                    <PdfViewer
                        fileBlobUrl={declarationOfParticipationFile}
                        onClose={toggleIsDeclarationOfParticipationOpen}
                    />
                )}
            </Flex>
        );
    }
}

export default connect(
    connector.mapStateToProps,
    connector.mapDispatchToProps,
)(withRouter(withTranslation()(Registration)));
