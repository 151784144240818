import React, {Component} from 'react';
import {
    withStyles,
    MenuItem,
    ListItemText,
    Typography,
    Paper,
} from "@material-ui/core";
import Flex from 'components/grid/Flex';
import {alabaster, htmlLightGrey} from 'components/colors/Colors';
import {withTranslation} from 'react-i18next';
import MultiInput from 'components/input/MultiInput';
import Indications from './Indications';
import InsuranceCompany from './InsuranceCompany';
import CostCoverage from './CostCoverage';
import CaseDatePicker from 'components/calendar/CaseDatePicker';
import EmailManager from 'scenes/case/edit/component/confirmation/EmailManager';
import {sideBarIndex} from 'components/zindex/zIndex';
import Accordion from 'components/grid/Accordion';
import CaseContactPerson from './CaseContactPerson';
import UserManager from 'scenes/user-manager/UserManager';
import DefaultTextField from 'components/hmo-textfield/DefaultTextField';
import {emptyCaseContactPerson} from 'domain/CaseContactPerson';
import HmoBorderedButton from 'components/button/HmoBorderedButton';
import AccessManager from 'scenes/user-manager/access-manager/AccessManager';
import {PATIENT, CONTACT_PERSON} from 'domain/User.model';
import {styled} from '@material-ui/core/styles';
import InvitedContactPerson from './InvitedContactPerson';
import {CASE} from 'domain/EntityAuthorizationType.model';
import MultiInputEmailAddressEditor from 'scenes/user-manager/access-manager/MultiInputEmailAddressEditor';
import { DeleteContactInvitationModal } from "components/contacts/DeleteContactInvitationModal";

const Container = styled((props) => <Paper {...props} />)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    marginBottom: 5,
    boxShadow: theme.shadows[2]
}));

const DateOfBirthTextField = withTranslation()(({t, tReady, ...other}) =>
        <DefaultTextField label={t("case.date-of-birth")} {...other}/>)
const DateOfDeathTextField = withTranslation()(({t, tReady, ...other}) =>
        <DefaultTextField label={t("case.date-of-death")} {...other}/>)

const styles = theme => ({
    container: {
        overflow: 'auto',
        height: '100vh',
        padding: 10,
        backgroundColor: alabaster,
        zIndex: sideBarIndex,
        width: 470,
    }
});

export const Amount = props => {
    const {translate, costCoverageValue, handleCaseChange, costCoverageAmounts, isEditDisabled} = props;
    const selectedAmount = (costCoverageValue && costCoverageAmounts.find(amountInArray => amountInArray.value === costCoverageValue))
            || costCoverageAmounts.find(amountInArray => amountInArray.default);
    return (
            <Flex container item>
                <DefaultTextField label={translate("case.amount")}
                                  select
                                  disabled={isEditDisabled}
                                  SelectProps={{
                                      value: selectedAmount.value || '',
                                      onChange: handleCaseChange,
                                      name: 'costCoverageValue',
                                      renderValue: value => value
                                  }}>
                    {costCoverageAmounts && costCoverageAmounts.map(amountInArray => (
                            <MenuItem key={amountInArray.value} value={amountInArray.value}
                                      disabled={amountInArray.value === '0.00 EUR'}>
                                <ListItemText primary={amountInArray.value + " " + amountInArray.description}/>
                            </MenuItem>
                    ))}
                </DefaultTextField>
                <Flex item={"1 1 50%"} container alignItems={'center'}
                      style={{marginLeft: 5, marginTop: 10, maxWidth: 270}}>
                    <Typography
                            style={{
                                fontWeight: 'bold',
                                color: htmlLightGrey
                            }}>{selectedAmount?.description}</Typography>
                </Flex>
            </Flex>
    )
};

export class PatientSidebar extends Component {


    state = {
        accordionOpen: 'PATIENT',
        deleteContactInvitationModelOpen: false,
        deleteContactInvitation: null,
    }

    handlePatientChange = event => {
        this.props.updatePatient({
            [event.target.name]: event.target.value
        })
    };

    handleOriginChangeChange = event => {
        let sourceFreeText = null;
        if (event.target.value === ' Presse') {
            sourceFreeText = this.props.patient.sourceFreeText
        }
        this.props.updatePatient({
            source: event.target.value,
            sourceFreeText
        })
    };

    addCaseContactPerson = () => {
        const emptyContact = emptyCaseContactPerson();
        this.props.createUserDetails({
            userDetailsData: emptyContact,
            invitedRoleType: CONTACT_PERSON,
            //TODO LM: CASE??? WTF is going on here ?
            entityType: CASE,
            entityId: this.props._case.id
        }).then(() => {
            this.refreshCase();
        });
        this.setState({
            accordionOpen: emptyContact.businessId
        });
    }

    setAccordionOpen = id => event => {
        this.setState({
            accordionOpen: id === this.state.accordionOpen ? '' : id
        })
    }

    refreshCase = () => {
        this.props.fetchCase(this.props._case.businessId).then(() => {
            this.props.getAlreadyInvitedEmails();
        });
    }

    render() {
        const {
            classes, configuration, t: translate, updatePatient, _case, updateCase, handleCaseChange, isEditDisabled,
            save, rejectAccessToEntityForInvitedEmail,
        } = this.props;
        const {
            handlePatientChange, handleOriginChangeChange, addCaseContactPerson, setAccordionOpen, refreshCase
        } = this;
        const {accordionOpen, deleteContactInvitationModelOpen } = this.state;
        const patient = _case.patient || {};
        const excludedUserIds = _case.contactPersons
                .filter(contact => contact.userId)
                .map(contact => contact.userId);
        if (patient.userId) excludedUserIds.push(patient.userId);

        return (
            <Flex item container column className={classes.container}>
                <Flex item={"0 1 auto"} container justifyContent={"space-between"}>
                    <HmoBorderedButton
                        size={"small"}
                        style={{
                            margin: 10,
                            textTransform: "capitalize",
                        }}
                        onClick={addCaseContactPerson}
                    >
                        {translate("global.add-contact-person")}
                    </HmoBorderedButton>
                </Flex>

                <Accordion
                    accordionOpen={accordionOpen === "PATIENT"}
                    setAccordionOpen={setAccordionOpen("PATIENT")}
                    titleComponent={
                        <Flex item container justifyContent={"space-between"}>
                            <Typography style={{ fontWeight: "bold" }}>
                                {(patient.title || "") +
                                    " " +
                                    (patient.givenName || "") +
                                    " " +
                                    (patient.familyName || "")}
                            </Typography>
                            <Typography style={{ fontWeight: "bold" }}>Patient</Typography>
                        </Flex>
                    }
                >
                    <Flex item container column>
                        <Flex item container style={{ padding: "7px 0px" }}>
                            {patient?.userBusinessId ? (
                                <UserManager
                                    {...{
                                        userBusinessId: patient?.userBusinessId,
                                        emailAddresses: patient?.emailAddresses,
                                    }}
                                />
                            ) : (
                                <AccessManager
                                    {...{
                                        patient,
                                        person: patient,
                                        invitedRoleType: PATIENT,
                                        excludedUserIds,
                                        existingInvitesForEntity: _case.alreadyInvitedEmails,
                                        callback: refreshCase,
                                    }}
                                />
                            )}

                            <EmailManager
                                isEditDisabled={isEditDisabled}
                                saveCase={save}
                                person={patient}
                                personType={PATIENT}
                            />
                        </Flex>

                        <Flex item container>
                            <DefaultTextField
                                label={translate("case.salutation")}
                                style={{ width: "100%" }}
                                select
                                disabled={isEditDisabled}
                                SelectProps={{
                                    value: patient.salutation || "",
                                    name: "salutation",
                                    onChange: handlePatientChange,
                                }}
                            >
                                {configuration.salutations &&
                                    configuration.salutations.map((salutation) => (
                                        <MenuItem key={salutation} value={salutation}>
                                            <ListItemText primary={translate(`global.${salutation}`)} />
                                        </MenuItem>
                                    ))}
                            </DefaultTextField>
                            <DefaultTextField
                                value={patient.title || ""}
                                style={{ width: "100%" }}
                                label={translate("case.title")}
                                disabled={isEditDisabled}
                                name={"title"}
                                onChange={handlePatientChange}
                            />
                        </Flex>

                        <DefaultTextField
                            value={patient.givenName || ""}
                            label={translate("case.first-name")}
                            error={!patient.givenName}
                            disabled={isEditDisabled}
                            name={"givenName"}
                            onChange={handlePatientChange}
                        />

                        <DefaultTextField
                            label={translate("case.last-name")}
                            value={patient.familyName || ""}
                            onChange={handlePatientChange}
                            error={!patient.familyName}
                            disabled={isEditDisabled}
                            name={"familyName"}
                        />

                        <Flex item container>
                            <DefaultTextField
                                label={translate("case.sex")}
                                select
                                disabled={isEditDisabled}
                                fullWidth
                                error={!patient.bioSex}
                                SelectProps={{
                                    value: patient.bioSex || "",
                                    onChange: handlePatientChange,
                                    name: "bioSex",
                                }}
                            >
                                {configuration.bioSexes &&
                                    configuration.bioSexes.map((bs) => (
                                        <MenuItem key={bs} value={bs}>
                                            <ListItemText primary={translate(`global.${bs}`)} />
                                        </MenuItem>
                                    ))}
                            </DefaultTextField>
                            <CaseDatePicker
                                {...{
                                    date: patient.dateOfBirth,
                                    name: "dateOfBirth",
                                    updateParent: updatePatient,
                                    error: !patient.dateOfBirth,
                                    disable: isEditDisabled,
                                    style: { marginRight: "unset", flex: "1 1 auto", width: "100%" },
                                    TextFieldComponent: DateOfBirthTextField,
                                }}
                            />
                        </Flex>

                        <DefaultTextField
                            label={translate("case.who-asks")}
                            value={_case.whoAsks || ""}
                            onChange={handleCaseChange}
                            disabled={isEditDisabled}
                            name={"whoAsks"}
                        />
                        <Flex item container column>
                            {patient?.userEmailAddress &&
                                !patient.emailAddresses.includes(patient.userEmailAddress) && (
                                    <DefaultTextField
                                        value={patient.userEmailAddress || ""}
                                        label={translate("global.user-email-address")}
                                        disabled
                                        onChange={() => {}}
                                    />
                                )}
                            <MultiInputEmailAddressEditor
                                {...{
                                    emailAddresses: patient.emailAddresses,
                                    invitedRoleType: PATIENT,
                                    person: patient,
                                    disabled: isEditDisabled,
                                    updateInputValues: updatePatient,
                                    refresh: refreshCase,
                                }}
                            />
                        </Flex>
                        <Flex item container column>
                            <MultiInput
                                {...{
                                    inputValues: patient.phoneNumbers,
                                    translate,
                                    disabled: isEditDisabled,
                                    name: "phoneNumbers",
                                    updateInputValues: updatePatient,
                                }}
                            />
                        </Flex>

                        <DefaultTextField
                            label={translate("case.street")}
                            value={patient.street || ""}
                            onChange={handlePatientChange}
                            disabled={isEditDisabled}
                            name={"street"}
                        />

                        <DefaultTextField
                            label={translate("case.zip")}
                            value={patient.zipCode || ""}
                            error={!patient.zipCode}
                            onChange={handlePatientChange}
                            disabled={isEditDisabled}
                            name={"zipCode"}
                        />

                        <DefaultTextField
                            label={translate("case.city")}
                            value={patient.city || ""}
                            error={!patient.city}
                            onChange={handlePatientChange}
                            disabled={isEditDisabled}
                            name={"city"}
                        />

                        <DefaultTextField
                            label={translate("case.state")}
                            select
                            disabled={isEditDisabled}
                            error={!patient.state}
                            SelectProps={{
                                value: patient.state || "",
                                onChange: handlePatientChange,
                                name: "state",
                            }}
                        >
                            {configuration.states &&
                                configuration.states.map((stateInArray) => (
                                    <MenuItem key={stateInArray} value={stateInArray}>
                                        <ListItemText primary={stateInArray} />
                                    </MenuItem>
                                ))}
                        </DefaultTextField>

                        <DefaultTextField
                            label={translate("case.country")}
                            select
                            disabled={isEditDisabled}
                            error={!patient.country}
                            SelectProps={{
                                value: patient.country || "",
                                onChange: handlePatientChange,
                                name: "country",
                            }}
                        >
                            {configuration.countries &&
                                configuration.countries.map((countryInArray) => (
                                    <MenuItem key={countryInArray} value={countryInArray}>
                                        <ListItemText primary={countryInArray} />
                                    </MenuItem>
                                ))}
                        </DefaultTextField>

                        <Flex item container column>
                            <DefaultTextField
                                label={translate("case.origin")}
                                select
                                disabled={isEditDisabled}
                                SelectProps={{
                                    value: patient.source || "",
                                    onChange: handleOriginChangeChange,
                                    name: "source",
                                }}
                            >
                                {configuration.sources &&
                                    configuration.sources.map((sourceInArray) => (
                                        <MenuItem key={sourceInArray} value={sourceInArray}>
                                            <ListItemText primary={sourceInArray} />
                                        </MenuItem>
                                    ))}
                            </DefaultTextField>
                            {patient.source === "Presse" && (
                                <DefaultTextField
                                    label={"Presse"}
                                    value={patient.sourceFreeText || ""}
                                    onChange={handlePatientChange}
                                    disabled={isEditDisabled}
                                    name={"sourceFreeText"}
                                />
                            )}
                        </Flex>

                        <CaseDatePicker
                            {...{
                                date: patient.dateOfDeath,
                                name: "dateOfDeath",
                                updateParent: updatePatient,
                                disable: isEditDisabled,
                                style: { marginRight: "unset", flex: "1 1 auto", width: "100%" },
                                TextFieldComponent: DateOfDeathTextField,
                            }}
                        />
                    </Flex>
                </Accordion>

                {(_case.contactPersons || []).map((contactPerson) => (
                    <CaseContactPerson
                        key={contactPerson.businessId}
                        caseContactPerson={contactPerson}
                        accordionOpen={accordionOpen === contactPerson.businessId}
                        excludedUserIds={excludedUserIds}
                        refreshCase={refreshCase}
                        caseId={_case.id}
                        setAccordionOpen={setAccordionOpen(contactPerson.businessId)}
                    />
                ))}

                {(_case.alreadyInvitedEmails || []).map((invite) => (
                    <InvitedContactPerson
                        key={invite?.inviteToken}
                        onDelete={() => {
                            this.setState({ deleteContactInvitationModelOpen: true, deleteContactInvitation: invite });
                        }}
                        invite={invite}
                    />
                ))}

                <Container>
                    <CostCoverage
                        {...{
                            translate,
                            isEditDisabled,
                            selectedCostCoverageTypes: _case.costCoverageTypes,
                            costCoverageTypes: configuration.costCoverageTypes,
                            costCoverageAmounts: configuration.costCoverageAmounts,
                            updateCase,
                        }}
                    />

                    <Indications
                        {...{
                            translate,
                            indications: configuration.indications,
                            subIndications: configuration.subIndications,
                            selectedIndication: _case.indication,
                            selectedSubIndications: _case.subIndications,
                            updateCase,
                            isEditDisabled,
                        }}
                    />

                    <InsuranceCompany
                        {...{
                            translate,
                            isEditDisabled,
                            insuranceCompanies: configuration.insuranceCompanies,
                            insuranceCompanyId: _case.insuranceCompanyId,
                            updateCase,
                        }}
                    />

                    {_case.insuranceCompanyId === "0018" && (
                        <DefaultTextField
                            label={translate("case.regional-office")}
                            select
                            disabled={isEditDisabled}
                            SelectProps={{
                                value: _case.insuranceCompanyRegionalOffice || "",
                                onChange: handleCaseChange,
                                name: "insuranceCompanyRegionalOffice",
                            }}
                        >
                            {configuration.regionalInsuranceCompanies &&
                                configuration.regionalInsuranceCompanies.map((regionalOffice) => (
                                    <MenuItem key={regionalOffice} value={regionalOffice}>
                                        <ListItemText primary={regionalOffice} />
                                    </MenuItem>
                                ))}
                        </DefaultTextField>
                    )}

                    {(_case.insuranceCompanyId === "0028" || _case.insuranceCompanyId === "0029") && (
                        <DefaultTextField
                            label={translate("case.other-insurance")}
                            value={_case.otherInsurance || ""}
                            onChange={handleCaseChange}
                            disabled={isEditDisabled}
                            name={"otherInsurance"}
                        />
                    )}

                    <DefaultTextField
                        label={translate("case.insurance-number")}
                        value={_case.insuranceNumber || ""}
                        error={
                            _case.insuranceCompanyId !== "0027" &&
                            _case.insuranceCompanyId !== "0028" &&
                            _case.insuranceCompanyId !== "0029" &&
                            !_case.insuranceNumber
                        }
                        onChange={handleCaseChange}
                        disabled={isEditDisabled}
                        name={"insuranceNumber"}
                    />

                    <Amount
                        {...{
                            classes,
                            translate,
                            costCoverageValue: _case.costCoverageValue,
                            costCoverageAmounts: configuration.costCoverageAmounts,
                            isEditDisabled,
                            handleCaseChange,
                        }}
                    />
                </Container>

                <DeleteContactInvitationModal
                    patientFullName={this.state.deleteContactInvitation?.invitedName}
                    email={this.state.deleteContactInvitation?.invitedEmail}
                    open={deleteContactInvitationModelOpen}
                    onClose={() => {
                        this.setState({ deleteContactInvitationModelOpen: false, deleteContactInvitation: null });
                    }}
                    onDelete={() => {
                        rejectAccessToEntityForInvitedEmail({
                            email: this.state.deleteContactInvitation?.invitedEmail,
                            entityType: this.state.deleteContactInvitation?.entityType,
                            entityId: this.state.deleteContactInvitation?.entityId,
                        })
                            .then(() => {
                                this.refreshCase();
                            })
                            .finally(() => {
                                this.setState({
                                    deleteContactInvitationModelOpen: false,
                                    deleteContactInvitation: null,
                                });
                            });
                    }}
                />
            </Flex>
        );
    }
}

export default withStyles(styles)(withTranslation()(PatientSidebar));
